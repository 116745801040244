<template>
  <div>
    <div v-if="loading==false">
      <b-row>
        <b-col lg="6">
          <b-card title="Filtre transações">
            <b-form-select
              v-model="transactionStatus"
            >
              <b-form-select-option :value="'PENDING'">
                Pendente
              </b-form-select-option>
              <b-form-select-option :value="'CONFIRMED'">
                Pago
              </b-form-select-option>
              <b-form-select-option
                :value="'CANCELED'"
              >
                Cancelado

              </b-form-select-option>
              <b-form-select-option :value="'PROCESSING'">
                Processando
              </b-form-select-option>
              <b-form-select-option :value="'UNDER_ANALYSIS'">
                En Analisis
              </b-form-select-option>
              <b-form-select-option :value="'NOT_AUTHORIZED'">
                No Autorizado
              </b-form-select-option>
              <b-form-select-option :value="'VERIFIED_BY_SUP2'">
                Verificado por Soporte2
              </b-form-select-option>
            </b-form-select>
          </b-card>
        </b-col>

        <b-col>
          <b-card title="Data inicial">
            <template>
              <b-form-datepicker
                v-model="from"
                locale="pt-br"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
              />
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Data final">
            <template>
              <b-form-datepicker
                v-model="to"
                locale="pt-br"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
              />
            </template>
          </b-card>
        </b-col>
      </b-row>
      <withdraws-list
        :orders="withdraw_orders.data"
        :context-actions="'admin'"
        @emitSidebar="openTransactionSidebar"
      />
      <nav aria-label="...">
        <ul class="pagination">
          <li
            v-if="withdraw_orders.paginatorInfo.currentPage - 1 > 0"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(-1)"
            >Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ withdraw_orders.paginatorInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="withdraw_orders.paginatorInfo.currentPage < withdraw_orders.paginatorInfo.lastPage"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(+1)"
            >Proxima</span>
          </li>
        </ul>
      </nav>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
    <edit-transaction-sidebar
      ref="transactionSidebar"
      :transaction="transactionObj"
      @refreshOrders="refreshOrders()"
    />

  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio, BFormDatepicker,
  BFormSelect,
  BFormSelectOption,

} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import WithdrawsList from '@/components/transactions/WithdrawsList.vue'
import EditTransactionSidebar from '@/components/transactions/EditTransactionSidebar.vue'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BCardText,
    BLink,
    GeneralGroupsList,
    underscore: _,
    LogoLoaderAnimation,
    WithdrawsList,
    EditTransactionSidebar,
    BFormRadio,
    BCol,
    BRow,
    BFormSelect,
    BFormSelectOption,

  },
  data() {
    return {
      from: new Date().toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      currentPage: 1,
      queryResult: null,
      transactionObj: null,
      transactionStatus: 'PENDING',
      statusName: ['PENDING', 'CONFIRMED', 'CANCELED', 'PROCESSING', 'UNDER_ANALYSIS', 'NOT_AUTHORIZED', 'VERIFIED_BY_SUP2'],
      statusList: [{ value: 'PENDING', text: 'Pendente' }, { value: 'CONFIRMED', text: 'Pago' }, { value: 'CANCELED', text: 'Cancelado' }, { value: 'PROCESSING', text: 'Processando' }, { value: 'UNDER_ANALYSIS', text: 'En Analisis' }, { value: 'NOT_AUTHORIZED', text: 'No Autorizado' }, { value: 'VERIFIED_BY_SUP2', text: 'Verificado por Soporte2' }],
      loading: true,
      pageToSearch: 1,
      withdraw_orders: [],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  watch: {
    from() {
      this.search()
    },
    to() {
      this.search()
    },
    transactionStatus() {
      this.search()
    },
  },
  created() {
    this.search()
  },
  methods: {
    refreshOrders() {
      console.log('refresh')
      this.$apolloProvider.clients.cashioClient.reFetchObservableQueries()
      this.search()
    },
    openTransactionSidebar(e) {
      this.transactionObj = e
      this.$refs.transactionSidebar.toogleV()
    },
    paginate(eV) {
      if (this.pageToSearch == 1) {
        this.pageToSearch = 2
      } else {
        this.pageToSearch += eV
      }
    },

    search() {
      console.log('search', {
        status: this.transactionStatus,
        from: this.from,
        to: this.to,
        offset: this.pageToSearch,
      })
      this.loading = true
      this.$apollo.query(
        {
          query: gql`query($status:String, $offset:Int, $from: Mixed, $to: Mixed){
      withdraw_orders: 
        orders(first:30,order_type:"WITHDRAW",status:$status ,page:$offset,  where: {column:CREATED_AT, operator:GTE, value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
          paginatorInfo{
            currentPage,
            lastPage
          }
        }
      }`,
          client: 'cashioClient',
          variables: {
            status: this.transactionStatus,
            from: this.from,
            to: this.to,
            offset: this.pageToSearch,
          },
        },
      ).then(data => {
        console.log('data', data)
        this.withdraw_orders = data.data.withdraw_orders
        this.loading = false
      })
    },
  },
}
</script>

<style>

</style>
