<template>
  <div v-if="transaction!=null">
    <b-sidebar
      id="sidebar-deposit"
      v-model="visibility"
      backdrop-variant="primary"
      backdrop
      :right="true"
      title="Liberação de depósito"
    >
      <b-card>
        <hr
          data-content="Dados do depósito"
          class="hr-text"
        >
        <div class="w-100 justify-content-between">
          <b>Beneficiário:</b>
          <p>{{ transaction.target_bank.owner_name }}</p>
        </div>
        <div class="justify-content-between">
          <b>Banco:</b>
          <p>{{ transaction.target_bank.bank_name }}</p>
        </div>
        <div class="justify-content-between">
          <b>Agência:</b>
          <p>{{ transaction.target_bank.agency }}</p>
        </div>
        <div class="justify-content-between">
          <b>Conta:</b>
          <p>{{ transaction.target_bank.account }}</p>
        </div>
        <div class="text-success">
          <p><b>Valor da transação:</b> {{ transaction.amount | brl }}</p>
          <p><b>ID da transação:</b> {{ transaction.id | brl }}</p>
          <p><b>ID gerado em:</b> {{ transaction.created_at | brl }}</p>
        </div>
        <br>
        <div class="form-block">
          <hr
            data-content="Tipo de liberação"
            class="hr-text"
          >
          <b-form-group>
            <div class="d-flex text-center">

              <b-form-select
                v-model="liberationType"
                size="lg"
                class="mt-3 mb-3"
              >
                <b-form-select-option :value="'PENDING'">
                  Pendente
                </b-form-select-option>
                <b-form-select-option :value="'1'" v-if="$ability.can('withdrawal-status', 'confirm')">
                  Pago
                </b-form-select-option>
                <b-form-select-option
                  :value="'2'"
                >
                  Cancelado
                </b-form-select-option>
                <b-form-select-option :value="'4'">
                  En Analisis
                </b-form-select-option>
                <b-form-select-option :value="'5'">
                  No Autorizado
                </b-form-select-option>
                <b-form-select-option :value="'6'">
                  Verificado por Soporte2
                </b-form-select-option>
              </b-form-select>

            </div>

          </b-form-group>
        </div>
        <div
          v-if="liberationType == 2 || liberationType == 4 || liberationType == 5 || liberationType == 6"
          class="form-block"
        >
          <hr
            data-content="Motivo"
            class="hr-text"
          >
          <b-form-group>
            <div class="d-flex text-center">
              <b-form-input
                v-model="release_comment"
                class="input-rounded-full"
              />
            </div>
          </b-form-group>
        </div>
        <b-alert
          :show="dismissCountDown"
          fade
          variant="warning"
          class="p-1"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }}
        </b-alert>
        <div class="mt-2">
          <b-button
            block
            variant="gradient-primary"
            @click="alterTransaction()"
          >
            Liberar
          </b-button>
        </div>
      </b-card>
      <div
        v-if="loading"
        class="loading-bg"
      >
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </b-sidebar>
    <!-- <b-button @click="toogleV()">Abre/Fecha</b-button> -->
  </div>
</template>

<script>
import {
  BSidebar,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BCardText,
  BFormCheckbox,
  BSpinner,
  BAlert,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import { _ } from 'vue-underscore'
import gql from 'graphql-tag'

const delay = ms => new Promise(res => setTimeout(res, ms))

export default {
  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BCardText,
    BFormCheckbox,
    BSpinner,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    underscore: _,
  },
  props: ['transaction'],
  data() {
    return {
      visibility: false,
      liberationType: 0,
      statusName: ['CONFIRMED', 'CANCELED', 'PENDING', 'UNDER_ANALYSIS', 'NOT_AUTHORIZED', 'VERIFIED_BY_SUP2'],
      loading: false,
      dismissSecs: 2,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: '',
      release_comment: null,
    }
  },
  computed: {

  },
  created() {
  },
  methods: {
    toogleV() {
      this.visibility = !this.visibility
    },
    alterTransaction() {
      this.depositRelease()
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    depositRelease() {
      this.loading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($orderId: ID!,$statusName: String) {
          updateOrder(id: $orderId, status:$statusName) {
            id
            status
          }
        }`,
        variables: {
          orderId: this.transaction.id,
          statusName: this.statusName[this.liberationType - 1],
        },
      }).then(data => {
        // Result
        this.insertOrderTag()
      }).catch(error => {
        console.error(error)
      })
    },
    insertOrderTag() {
      this.$apollo.mutate({
        mutation: gql`mutation ($orderId: ID!,$value: String) {
          createOrderTag(order_id: $orderId, key:"RELEASE_COMMENT", value: $value) {
            id
          }
        }`,
        variables: {
          orderId: this.transaction.id,
          value: this.release_comment ? `${this.release_comment} Agente: ${JSON.parse(window.localStorage.getItem('userData')).fullName}` : `${this.statusName[this.liberationType - 1]} Agente: ${JSON.parse(window.localStorage.getItem('userData')).fullName}`,
        },
      }).then(data => {
        // Result
        this.$emit('refreshOrders', true)
        this.loading = false
        this.toogleV()
      }).catch(error => {
        console.error(error)
      })
    },
    generateWalletMovement() {
      const realWallet = this.$parent.$apolloData.data.wallets.data.filter(w => w.wallet_type_id == 1)[0]
      this.$apollo.mutate({
        mutation: gql`mutation (
          $code: String,
          $wallet_id: ID!,
          $external_transaction_id: String,
          $movement_type_id: Int!,
          $value: Float!,
          $description: String!,
          $movement_direction: MovementDirection!,
        ) {
          createMovement(
          code: $code,
          wallet_id: $wallet_id,
          external_transaction_id: $external_transaction_id,
          movement_type_id: $movement_type_id,
          value: $value,
          description: $description,
          movement_direction: $movement_direction,
          ){
            id
          }
        }`,
        variables: {
          code: realWallet.code,
          wallet_id: realWallet.id,
          external_transaction_id: this.deposit.id,
          movement_type_id: 1,
          value: this.deposit.amount,
          description: 'DEPOSIT VIA CAS',
          movement_direction: 'CREDIT',
        },
        client: 'walletClient',
      }).then(data => {
        // Result
        this.message = ''
        this.liberationType = 0
        this.loading = false
        console.log()
        this.$emit('refreshOrders', true)
        this.toogleV()
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>

<style>
  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-deposit___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
</style>
